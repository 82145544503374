import { register } from 'register-service-worker'
if (process.env.NODE_ENV === 'production') {
  register(`${process.env.BASE_URL}firebase-messaging-sw.js`, {
    ready () {
      console.log(
        'App is being served from cache by a service worker.\n' +
        'For more details, visit https://goo.gl/AFskqB'
      )
    },
    registered () {
      console.log('Service worker has been registered.')
    },
    cached () {
      console.log('Content has been cached for offline use.')
    },
    updatefound () {
      console.log('New content is downloading.')
    },
    updated (registration) {
      console.log('New content is available; refreshing now...')
      registration.waiting.postMessage({ type: 'SKIP_WAITING' })
      console.log('New content is available; please refresh the page.')
      // Notify users about the new content
      const message = document.createElement('div')
      message.innerText = 'New content is available! Refresh the page to see updates.'
      message.style.position = 'fixed'
      message.style.bottom = '20px'
      message.style.left = '20px'
      message.style.backgroundColor = 'rgba(0, 0, 0, 0.7)'
      message.style.color = 'white'
      message.style.padding = '10px'
      message.style.zIndex = '1000'
      document.body.appendChild(message)
    },
    offline () {
      console.log(
        'No internet connection found. App is running in offline mode.'
      )
    },
    error (error) {
      console.error('Error during service worker registration:', error)
    }
  })
}
